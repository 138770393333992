// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import '~font-awesome';

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~select2';
@import './custom';
